<template>
  <div id="balance-list">
    <v-navigation-drawer
      v-if="!status"
      :value="isFilterUserSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    >
      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">
              {{ icons.mdiFilter }}
            </v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
        </div>
        <v-btn v-if="false" icon x-small>
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>

      <div>
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="validfrom"
              label="Başlangıç Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="validfrom" no-title @input="startMenu = false"></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="validuntil"
              label="Bitiş Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="validuntil" no-title @input="endMenu = false"></v-date-picker>
        </v-menu>
        <v-select v-model="plant" :items="plants" label="Tesis" class="rounded-0" filled hide-details></v-select>
       
        <v-text-field v-model="materialName" class="rounded-0" filled hide-details label="Malzeme Adı"></v-text-field>
      </div>
      <template v-slot:append>
        <div class="pa-3">
          <v-btn x-large rounded block class="text-capitalize" color="secondary" @click="fetchBalances()">
            Tamam
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <div>
      <v-row dense>
        <v-col>
          <v-expand-transition>
            <v-card
              v-if="status"
              :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
              rounded="xl"
              class="mx-auto mt-md-9"
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
            >
              <v-img
                class="white--text align-end"
                :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                src="@/assets/images/pages/header-01.jpg"
                :height="$vuetify.breakpoint.smAndDown ? 260 : 260"
                gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
              >
                <div class="card-img--text card-img--text-type-1">
                  <div class="text-h3 font-weight-medium mb-0">Satış Analizi</div>
                  <p class="text-body-2 white--text font-weight-thin mb-1" v-text="msg" />
                  <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
                </div>
              </v-img>
              <v-expand-transition>
                <div v-show="!loading">
                  <v-card-text class="pa-0">
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : 'pt-6'">
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-menu
                          v-model="startMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="validfrom"
                              label="Fatura Başlangıç Tarihi"
                              hide-details=""
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="validfrom" no-title @input="startMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-menu
                          v-model="endMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="validuntil"
                              label="Fatura Bitiş Tarihi"
                              hide-details
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="validuntil" no-title @input="endMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="px-9">
                        <v-select v-model="plant" label="Tesis" :items="plants"></v-select>
                      </v-col>
                    </v-row>
                
                    <v-row>
                      <v-col cols="12" class="px-9">
                        <v-text-field v-model="materialName" label="Malzeme Adı" />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="pb-9 pt-9">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      class="text-capitalize text-body-1"
                      rounded
                      x-large
                      @click="fetchBalances()"
                    >
                      Ara
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>
          <v-card
            v-if="!status"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-xl'"
          >
            <v-toolbar
              v-if="!$vuetify.breakpoint.smAndDown"
              :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon large left>
                  {{ icons.mdiAbacus }}
                </v-icon>
                Satış Analizi
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="Satis_Analiz__EKSUN.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel>

              <v-btn plain text small @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
            </v-toolbar>
            <v-expansion-panels v-else v-model="panel" flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-body-2 font-weight-medium">
                    <v-icon left>
                      {{ icons.mdiAbacus }}
                    </v-icon>
                    Satış Analizi
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-divider></v-divider>
                  <v-list class="pa-0" color="grey">
                    <v-list-item @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                      <v-list-item-content>
                        <v-list-item-title>Filitre</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon :color="isFilterNumber ? 'tertiary' : null">
                          {{ icons.mdiFilter }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>İndir</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>
                          {{ icons.mdiDownload }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="tableItems"
              :options.sync="options"
              :items-per-page="-1"
              :mobile-breakpoint="300"
              :class="tableClass"
              fixed-header
              hide-default-footer
              height="calc(100dvh - 185px)"
              :loading="loading"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiDownload, mdiFilter, mdiSend, mdiAbacus } from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import balanceStoreModule from './balanceStoreModule'

export default {
  components: {
    CustomerList,
  },
  setup() {
    const EXTRACT_APP_STORE_MODULE_NAME = 'app-balance'
    const { isDark } = useAppConfig()

    // Register module
    if (!store.hasModule(EXTRACT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EXTRACT_APP_STORE_MODULE_NAME, balanceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXTRACT_APP_STORE_MODULE_NAME)
    })
    const isFilterUserSidebarActive = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
    const status = ref(true)
    const validfrom = ref(
      new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
    )
    const validuntil = ref(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const startMenu = ref(false)
    const endMenu = ref(false)
    const tableItems = ref([])
    const tableFooter = ref([])
    const customer = ref(null)
    const customerSearch = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const tableColumns = [
      {
        text: 'Tesis',
        value: 'PLANT',
        sortable: false,
        width: '100px',
      },
      {
        text: 'Başlangıç Tarihi',
        value: 'DESDLVDATE',
        sortable: false,
        width: '125px',
      },
      {
        text: 'Belge Numarası',
        value: 'DOCNUM',
        sortable: false,
        width: '125px',
      },
      {
        text: 'Müşteri',
        value: 'CUSTOMER',
        sortable: false,
        width: '130px',
      },
      {
        text: 'Adı',
        value: 'NAME1',
        sortable: false,
        width: '400px',
      },
      {
        text: 'Nama Sevk Cari',
        value: 'GOODRECV',
        sortable: false,
        width: '130px',
      },
      {
        text: 'Nama Sevk',
        value: 'GRCNAME1',
        sortable: false,
        width: '400px',
      },

      {
        text: 'Malzeme Kodu',
        value: 'MATERIAL',
        sortable: false,
        width: '300px',
      },
      {
        text: 'Malzeme Açıklaması',
        value: 'MTEXT',
        sortable: false,
        width: '300px',
      },
      {
        text: 'Ödeme Planı',
        value: 'ESMPAYCOND',
        sortable: false,
        width: '120px',
      },
      {
        text: 'Miktar',
        value: 'QUANTITY',
        align: 'right',
        sortable: false,
        width: '90px',
      },
      {
        text: 'Miktar Br.',
        value: 'QUNIT',
        sortable: false,
        width: '90px',
      },
      {
        text: 'Birim Fiyat',
        value: 'SPRICE',
        align: 'right',
        sortable: false,
        width: '120px',
      },
      {
        text: 'Seçili Kur  İnd.li Neti',
        value: 'GROSS',
        align: 'right',
        sortable: false,
        width: '200px',
      },
      {
        text: 'Toplam Ağırlık(KG)',
        value: 'TOTALWEIGHT',
        sortable: false,
        width: '150px',
      },
    ]
    const totalBalanceListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const selectedRows = ref([])
    const material = ref('')
    const materialName = ref('')

    const json_data = ref([])
    const plant = ref('')
    const plants = ref([
      {
        text: 'Tümü',
        value: '',
      },
      {
        text: 'Tekirdağ',
        value: '01',
      },
      {
        text: 'Konya',
        value: '02',
      },
    ])

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          statusPlasiyer.value = true
        }
      },
    )
    const balances = (items, index, array) => {
      const DESDLVDATE = items.DESDLVDATE?.slice(0, 10).replaceAll(' ', '')

      const item = {
        PLANT: items.PLANT === '01' ? 'Tekirdağ' : 'Konya',
        CUSTOMER: items.CUSTOMER,
        DOCNUM: items.DOCNUM,
        NAME1: items.NAME1,
        MATERIAL: items.MATERIAL,
        MTEXT: items.MTEXT,
        MATERIAL: items.MATERIAL,
        GOODRECV: items.GOODRECV,
        GRCNAME1: items.GRCNAME1,
        QUANTITY: Number(items.QUANTITY),
        QUNIT: items.QUNIT,
        SPRICE: new Intl.NumberFormat('tr-TR').format(items.SPRICE),
        GROSS: new Intl.NumberFormat('tr-TR').format(items.GROSS),
        DESDLVDATE,
        ESMPAYCOND: items.ESMPAYMCOND,
        TOTALWEIGHT: new Intl.NumberFormat('tr-TR').format(items.TOTALWEIGHT),
      }

      tableItems.value.push(item)
      json_data.value.push(item)
    }

    const statusPlasiyer = ref(false)
    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        statusPlasiyer.value = false
      } else {
        statusPlasiyer.value = true
      }
    })
    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          statusPlasiyer.value = true
        }
      },
    )

    const sumQUANTITY = ref(0)
    const sumSPRICE = ref(0)
    const sumGROSS = ref(0)
    const sumESMPAYMCOND = ref(0)
    const sumTOTALWEIGHT = ref(0)
    const tableClass = ref('')
    const fetchBalances = () => {
      isFilterUserSidebarActive.value = false
      tableItems.value = []
      tableFooter.value = []
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      const fetchBalancesData = {
        method: 'getSalt70',
        validfrom: validfrom.value,
        validuntil: validuntil.value,
        customer: store.state.plasiyerCustomer || '',
        plant: plant.value,
        material: material.value,
        mtext: materialName.value,
      }

      store
        .dispatch('app-balance/fetchBalances', fetchBalancesData)
        .then(response => {
          if (response.error === 1) {
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            response.detail.filter(balances)

            sumQUANTITY.value = response.detail.reduce((partialSum, item) => partialSum + Number(item.QUANTITY), 0)
            sumGROSS.value = response.detail.reduce((partialSum, item) => partialSum + Number(item.GROSS), 0)
            sumESMPAYMCOND.value = response.detail.reduce(
              (partialSum, item) => partialSum + Number(item.ESMPAYMCOND),
              0,
            )
            sumTOTALWEIGHT.value = response.detail.reduce(
              (partialSum, item) => partialSum + Number(item.TOTALWEIGHT),
              0,
            )
            if (response.detail.length > 1) {
              tableClass.value = 'fixed-last-row'
              tableItems.value.push({
                PLANT: '',
                CUSTOMER: '',
                DOCNUM: '',
                NAME1: '',
                MATERIAL: '',
                MTEXT: '',
                GOODRECV: '',
                GRCNAME1: '',
                QUNIT: '',
                DESDLVDATE: '',
                QUANTITY: sumQUANTITY.value,
                SPRICE: '',
                GROSS: new Intl.NumberFormat('tr-TR').format(sumGROSS.value),
                ESMPAYCOND: '',
                TOTALWEIGHT: new Intl.NumberFormat('tr-TR').format(sumTOTALWEIGHT.value),
              })
            } else {
              tableClass.value = ''
            }

            totalBalanceListTable.value = Number(response.detail.length)
            status.value = false
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    }

    watch([customerSearch], val => {
      customerLoading.value = true
      store
        .dispatch('app/fetchCustomersBySalDept', val)
        .then(response => {
          customerItems.value = response.detail
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          customerLoading.value = false
        })
    })

    return {
      tableClass,
      material,
      materialName,
      plant,
      plants,
      customerItems,
      customerSearch,
      customerLoading,
      customer,
      isDark,
      tableColumns,
      tableItems,
      json_data,
      tableFooter,
      loading,
      selectedRows,
      options,
      validfrom,
      validuntil,
      startMenu,
      endMenu,
      msg,
      status,
      userData,
      totalBalanceListTable,
      fetchBalances,
      isFilterUserSidebarActive,
      statusPlasiyer,

      // icons
      icons: {
        mdiDownload,
        mdiAbacus,
        mdiFilter,
        mdiSend,
      },
    }
  },
  data() {
    return {
      dataTableHeight: '',
      height: {
        system: 0,
        top: 95,
        footer: 51,
      },
      json_fields: {
        Tesis: 'PLANT',
        'Başlangıç Tarihi': 'DESDLVDATE',
        'Belge Numarası': 'DOCNUM',
        Müşteri: 'CUSTOMER',
        Adı: 'NAME1',
        'Nama Sevk Cari': 'GOODRECV',
        'Nama Sevk': 'GRCNAME1',
        'Malzeme Açıklaması': 'MTEXT',
        'Ödeme Planı': 'ESMPAYCOND',
        Miktar: 'QUANTITY',
        'Miktar Br.': 'QUNIT',
        'Birim Fiyat': 'SPRICE',
        'Seçili Kur İnd.li Neti': 'GROSS',
        'Toplam Ağırlık(KG)': 'TOTALWEIGHT',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 60 - this.height.top - this.height.footer - 1
    },
  },
}
</script>
<style lang="scss">
.v-data-table.fixed-last-row {
  tr {
    &:last-child {
      position: sticky;
      bottom: 0;
      background-color: #201c30;
      &:hover {
        background-color: #201c30 !important;
      }
      td {
        height: 32px !important;
        color: white !important;
        font-weight: 800;
        border-top: 1px solid #4b4764;
      }
    }
  }
}
</style>
